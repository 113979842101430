<template>
  <ActionModal
    v-if="isModalShown"
    :width="'600px'"
    :height="'770px'"
    :isModalShown="isModalShown"
    :isLoading="isModalLoading"
  >
    <div class="modal-header">
      <div class="modal-title">Attach Users</div>
      <div class="close-modal-btn" @click="$emit('hideModal')"></div>
    </div>
    <div class="search-input-wrapper">
      <SearchField
        @search="handleSearch($event)"
        @cancelSearch="handleSearch($event)"
      />
    </div>
    <div class="main-card-wrapper">
      <AttachUsersTableHeader />
      <DividerLine />

      <div class="members-list">
        <AttachUsersTableRow
          v-for="user in filteredUsers"
          :key="user.id"
          :user="user"
          :managerId="managerId"
        />
      </div>
      <DividerLine />
    </div>
  </ActionModal>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import ActionModal from '../../../common/ActionModal.vue'
import AttachUsersTableHeader from '../../../common/mainContent/AttachUsersTableHeader.vue'
import AttachUsersTableRow from '../../../common/mainContent/AttachUsersTableRow.vue'
import MainCard from '@/components/common/mainContent/MainCard.vue'
import DividerLine from '@/components/common/DividerLine.vue'
import SearchField from '../../../common/header/SearchField.vue'

export default {
  props: ['isModalShown', 'isModalLoading', 'managerId'],

  components: {
    ActionModal,
    AttachUsersTableHeader,
    AttachUsersTableRow,
    MainCard,
    DividerLine,
    SearchField,
  },
  data() {
    return {
      userName: '',
    }
  },
  watch: {
    async isModalShown(newValue) {
      if (newValue) {
        await this.getInitialData()
        this.userName = ''
      }
    },
  },
  methods: {
    ...mapActions(['getUsersToAttachList']),
    async getInitialData() {
      try {
        await this.getUsersToAttachList()
      } catch (error) {
        this.errorMessage = error
      }
    },
    handleSearch(value) {
      this.userName = value
    },
  },
  computed: {
    ...mapState(['users']),
    filteredUsers() {
      if (this.users.usersToAttach) {
        this.users.usersToAttach = this.users.usersToAttach.filter(
          (user) => user.id !== this.managerId
        )
      }
      if (this.userName === '') return this.users.usersToAttach
      return this.users.usersToAttach.filter((user) => {
        return user.full_name
          .toLowerCase()
          .includes(this.userName.toLowerCase())
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styleVars.scss';

.search-input-wrapper {
  align-self: flex-start;
  margin-left: 30px;
  margin-top: 15px;
}

.members-list {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.modal-header {
  width: calc(100% - 80px);
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .modal-title {
    font-size: $fontSizeModalTitle;
  }

  .close-modal-btn {
    width: 24px;
    height: 24px;
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/icons/close-icon.svg');
    cursor: pointer;
  }

  .attach-modal-main-card {
    width: 100%;
    height: 700px;
    margin: 0;
  }
}

.main-card-wrapper {
  width: calc(100% - 80px);
  height: calc(100% - 204px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin: 0 40px;
  margin-top: 20px;
  border-radius: 4px;
  background-color: $whiteColor;
}

@media (max-height: 900px) {
  .main-card-wrapper {
    height: 646px;
  }
}

@media (max-width: 420px) {
  .main-card-wrapper {
    overflow-x: scroll;
    overflow-y: scroll;
  }
}
</style>

<template>
  <ActionModal
    v-if="isModalShown"
    :width="'calc(100% - 50px)'"
    :height="'calc(100% - 50px)'"
    :isModalShown="isModalShown"
    :isLoading="isModalLoading"
  >
    <div class="modal-header">
      <div class="modal-title">{{ memberData?.member?.full_name }}</div>
      <div class="action-menu-month">
        <div class="action-menu-title">Previous Pipelines</div>
        <div class="action-menu-wrapper">
          <div class="month-wrapper">
            <div class="reports-month-field" @click="openMonthDropDown">
              {{ textMonth }}
              <div
                class="open-dropdown-btn"
                :class="{ 'close-dropdown-btn': isMonthDropdownOpened }"
              ></div>
            </div>
            <ActionMenu
              ref="actionsMenu"
              :width="'200px'"
              :top="'55px'"
              :height="'444px'"
              :isActionMenuShown="isMonthDropdownOpened"
              @closeActionMenu="closeMonthDropDown"
            >
              <ActionMenuItem
                v-for="month in memberPipelinesList"
                :key="month.id"
                :text="setPipelineDate(month.timestamp)"
                @actionMenuItemClick="
                  setNewMonth(setPipelineDate(month.timestamp), month.id)
                "
              />
              <div class="loading-list-container" v-if="isLoading">
                <div class="spinner" v-if="isLoading"></div>
              </div>
            </ActionMenu>
          </div>
          <ActionButton
            v-if="isSalesManager && !isGenerateReportLoading"
            :style="{
              cursor: isGenerateBtnDisabled ? 'not-allowed' : 'pointer',
              background: isGenerateBtnDisabled ? '#C9CDE8' : '#69C9A0',
            }"
            :width="'200px'"
            :height="'44px'"
            :text="'Generate Report'"
            @click="generateReport"
          />
          <div
            class="action-btn loading-indicator-container"
            v-if="isGenerateReportLoading"
          >
            <div class="spinner"></div>
          </div>
        </div>

        <div class="action-btn loading-indicator-container" v-if="isLoading">
          <div class="spinner"></div>
        </div>
      </div>
      <div class="close-modal-btn" @click="closeModal"></div>
    </div>
    <div class="modal-header-row">
      <div class="title-container">
        <div class="title">
          Pipeline Value
          {{ formatPipelineValue(memberData?.pipeline?.projected_value) }}
        </div>
        <div class="current-date">{{ textMonth }}</div>
      </div>
      <!-- <div class="vertical-divider"></div> -->
    </div>
    <div class="modal-header-row">
      <span v-if="isGoalExist">Goals:</span>
      <div v-if="isGoalExist" class="goal-values">
        <PipelineGoalField
          :value="memberData.pipeline?.goal_one"
          :placeholder="'Monthly Revenue Goal'"
          :isNotEditable="true"
          :isSubscriptionActive="false"
          :fitContent="true"
        />
        <PipelineGoalField
          :value="memberData.pipeline?.goal_two"
          :isNotEditable="true"
          :placeholder="'Confidence Level %'"
          :isSubscriptionActive="false"
          :fitContent="true"
        />
        <PipelineGoalField
          :value="memberData.pipeline?.goal_three"
          :isNotEditable="true"
          :placeholder="'Non-revenue Goal'"
          :isSubscriptionActive="false"
          :fitContent="true"
        />
      </div>
    </div>
    <div class="modal-header-menu">
      <ul class="tab-modal-element-wrapper">
        <li
          class="tab-element"
          :class="{
            'tab-element-active-state': currentActiveTab === 'Clients',
          }"
          @click="handleClickTab('Clients')"
        >
          <title>Clients</title>
        </li>
        <li
          class="tab-element"
          :class="{
            'tab-element-active-state': currentActiveTab === 'Prospects',
          }"
          @click="handleClickTab('Prospects')"
        >
          <title>Prospects</title>
        </li>
      </ul>
    </div>
    <div class="modal-table-body">
      <div class="table-sort-options">
        <ul class="sort-table-options">
          <li
            v-for="sortOption in currentSortOptions"
            :key="sortOption"
            class="sort-table-item"
            :class="{
              'active-sort-state':
                currentActiveSort[currentActiveTab] === sortOption,
            }"
            @click="handleClickSortOption(sortOption)"
          >
            {{ sortOption }}
          </li>
        </ul>
      </div>
      <MainCard class="modal-main-card">
        <CardTableHeader
          :isCompanyShown="true"
          :isValueShown="true"
          :isNameSortArrowHiddent="true"
          :isCompanySortArrowHiddent="true"
          :isValueSortArrowHiddent="true"
          :isClosedDealArrowHidden="true"
          :isActionHeaderHiddent="true"
          :isNoteShown="true"
          :isNotInPipelineValue="
            this.currentActiveSort[this.currentActiveTab] === 'Not In Pipeline'
          "
          :order="''"
        />
        <DividerLine />
        <div :class="`${currentActiveTab.toLowerCase()}-list`">
          <LookInMembersCardRow
            v-for="member in filteredDeals"
            :key="member.id"
            :id="member.id"
            :subjectID="member.subject"
            :note="member.subject_note"
            :subject_name="getSubjectName(member)"
            :subject_company="getSubjectCompany(member)"
            :value="getValue(member)"
          />
        </div>
        <div class="error-message" v-if="errorMessage">
          {{ errorMessage }}
        </div>
        <Spinner v-if="isLoading" />
        <DividerLine />
      </MainCard>
    </div>
  </ActionModal>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import ActionModal from '@/components/common/ActionModal.vue'
import PipelineGoalField from '@/components/common/mainContent/PipelineGoalField.vue'
import Prospects from '@/components/crm/prospects/Prospects.vue'
import MainCard from '@/components/common/mainContent/MainCard.vue'
import CardTableHeader from '@/components/common/mainContent/CardTableHeader.vue'
import DividerLine from '@/components/common/DividerLine.vue'
import MembersCardRow from '@/components/admin/members/MembersCardRow.vue'
import Spinner from '@/components/common/Spinner.vue'
import CardFooter from '@/components/common/mainContent/CardFooter.vue'
import ActionMenu from '@/components/common/actionMenu/ActionMenu.vue'
import ActionMenuItem from '@/components/common/actionMenu/ActionMenuItem.vue'
import ActionButton from '@/components/common/ActionButton.vue'

import { getPipelineDate, months } from '@/utils/helpers'

import { getMemberData } from '@/api/usersApi'
import { getUserPipelinesList } from '@/api/pipelinesApi'
import LookInMembersCardRow from '@/components/admin/LookInMembersCardRow.vue'
import { UserRole } from '../../utils/constants'
export default {
  name: 'LookInModal',
  props: ['isModalShown', 'isModalLoading', 'memberId'],
  components: {
    LookInMembersCardRow,
    Prospects,
    PipelineGoalField,
    ActionModal,
    MainCard,
    CardTableHeader,
    DividerLine,
    MembersCardRow,
    Spinner,
    CardFooter,
    ActionMenu,
    ActionMenuItem,
    ActionButton,
  },
  data() {
    return {
      memberData: {},
      memberPipelinesList: [],
      pipelinesCount: 0,
      pipelineMonthArray: months,
      textMonth: '',
      currentPipelineId: 0,
      isMonthDropdownOpened: false,
      isLoading: false,
      errorMessage: '',
      currentActiveTab: 'Clients',
      lastId: 0,
      currentActiveSort: {
        Clients: 'In Pipeline',
        Prospects: 'Hot',
      },
      sortOptions: {
        Clients: ['In Pipeline', 'Not In Pipeline'],
        Prospects: ['Hot', 'In Progress', 'Target', 'Not In Pipeline'],
      },
      UserRole,
      isGenerateReportLoading: false,
    }
  },

  watch: {
    async isModalShown(newValue, OldValue) {
      if (newValue) {
        await this.getMemberPipelinesList()
        await this.getInitialData()

        this.handlePipelinesScroll()
      }
    },
    async currentPipelineId(newValue, OldValue) {
      if (newValue && newValue !== OldValue) {
        await this.getInitialData()
      }
    },
  },
  computed: {
    ...mapState(['currentUser']),
    isSalesManager() {
      return this.currentUser.role === this.UserRole.SalesManager
    },
    isGenerateBtnDisabled() {
      return (
        !this.memberData.client_deals?.length &&
        !this.memberData.hot_deals?.length &&
        !this.memberData.in_progress_deals?.length &&
        !this.memberData.tagret_deals?.length &&
        !this.memberData.not_in_pipeline_prospects?.length
      )
    },
    filteredDeals() {
      let deals = []
      if (this.currentActiveTab === 'Clients') {
        if (this.currentActiveSort['Clients'] === 'In Pipeline') {
          deals = this.memberData.client_deals
        } else if (this.currentActiveSort['Clients'] === 'Not In Pipeline') {
          deals = this.memberData.not_in_pipeline_clients
        }
      } else if (this.currentActiveTab === 'Prospects') {
        if (this.currentActiveSort['Prospects'] === 'Hot') {
          deals = this.memberData.hot_deals
        } else if (this.currentActiveSort['Prospects'] === 'In Progress') {
          deals = this.memberData.in_progress_deals
        } else if (this.currentActiveSort['Prospects'] === 'Target') {
          deals = this.memberData.tagret_deals
        } else if (this.currentActiveSort['Prospects'] === 'Not In Pipeline') {
          deals = this.memberData.not_in_pipeline_prospects
        }
      }
      return deals
    },
    currentSortOptions() {
      return this.sortOptions[this.currentActiveTab]
    },
    isGoalExist() {
      return (
        this.memberData?.pipeline?.goal_one ||
        this.memberData?.pipeline?.goal_two ||
        this.memberData?.pipeline?.goal_three
      )
    },
  },
  methods: {
    ...mapActions(['generateMemberReport']),
    async generateReport() {
      if (this.isGenerateBtnDisabled) return
      this.isGenerateReportLoading = true
      await this.generateMemberReport({
        pipelineId: this.currentPipelineId,
        userId: this.memberId,
      })
      this.isGenerateReportLoading = false
    },
    async updateMemberPipelinesList() {
      this.$refs.actionsMenu.$el.removeEventListener('scroll', this.listener)

      this.lastId =
        this.memberPipelinesList[this.memberPipelinesList.length - 1].id
      const data = await getUserPipelinesList(this.memberId, this.lastId)
      this.memberPipelinesList.push(...data.next_page)

      if (this.memberPipelinesList.length < this.pipelinesCount) {
        this.$refs.actionsMenu.$el.addEventListener('scroll', this.listener)
      }
    },
    listener() {
      const scrolled =
        this.$refs.actionsMenu.$el.scrollTop +
        this.$refs.actionsMenu.$el.clientHeight
      const scrollHeight = this.$refs.actionsMenu.$el.scrollHeight - 20

      if (scrolled > scrollHeight) {
        this.updateMemberPipelinesList()
      }
    },
    handlePipelinesScroll() {
      if (!this.$refs.actionsMenu.$el) return
      this.$refs.actionsMenu.$el.addEventListener('scroll', this.listener)
    },
    async getInitialData() {
      try {
        this.errorMessage = ''
        this.memberData = await getMemberData(
          this.memberId,
          this.currentPipelineId
        )
      } catch (error) {
        this.errorMessage = error
      }
    },
    async getMemberPipelinesList() {
      try {
        const data = await getUserPipelinesList(this.memberId, this.lastId)
        this.memberPipelinesList.push(...data.next_page)
        this.currentPipelineId = data.next_page[0].id
        this.pipelinesCount = data.pipelines_count
        this.textMonth = this.setPipelineDate(data.next_page[0].timestamp)
      } catch (error) {
        this.errorMessage = error
      }
    },
    getSubjectName(member) {
      return this.currentActiveSort[this.currentActiveTab] !== 'Not In Pipeline'
        ? member.subject_name
        : member.name
    },
    getSubjectCompany(member) {
      return this.currentActiveSort[this.currentActiveTab] !== 'Not In Pipeline'
        ? member.subject_company
        : member.company
    },
    getValue(member) {
      return this.currentActiveSort[this.currentActiveTab] !== 'Not In Pipeline'
        ? member.value
        : ''
    },
    getPipelineDate,
    formatPipelineValue(value) {
      if (value && value > 0) {
        return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      return '$0'
    },
    handleClickTab(CurrentActiveTabs) {
      this.currentActiveTab = CurrentActiveTabs
      this.$emit('changeActiveTab', this.currentActiveTab)
    },
    handleClickSortOption(sortOption) {
      this.currentActiveSort[this.currentActiveTab] = sortOption
    },
    closeMonthDropDown() {
      this.isMonthDropdownOpened = false
    },
    setPipelineDate(timestamp) {
      const date = new Date(timestamp * 1000)
      const pipelineDate =
        this.pipelineMonthArray[date.getUTCMonth()] +
        '  ' +
        date.getUTCFullYear()

      return pipelineDate
    },
    setActivePipeline() {
      console.log('setActivePipeline')
      const date = new Date(this.activePipeline.timestamp * 1000)
      const activePipelineDate =
        this.pipelienMonthArray[date.getUTCMonth()] +
        ' ' +
        date.getUTCFullYear()

      this.textMonth = activePipelineDate
      this.currentPipelineId = this.activePipeline.id
    },
    setNewMonth(month, pipelineId) {
      this.isMonthDropdownOpened = false
      this.currentPipelineId = pipelineId
      this.textMonth = month
    },
    openMonthDropDown() {
      this.isMonthDropdownOpened = true
    },
    closeMonthDropDown() {
      this.isMonthDropdownOpened = false
    },
    closeModal() {
      this.errorMessage = ''
      this.lastId = 0
      this.memberPipelinesList = []
      this.pipelinesCount = 0
      this.textMonth = ''
      this.$emit('hideModal')
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styleVars';

.modal-header {
  width: calc(100% - 80px);
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $fontColorBlack;

  .modal-title {
    font-size: $fontSizeModalTitle;
    color: $fontColorBlack;
  }

  .close-modal-btn {
    width: 24px;
    height: 24px;
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/icons/close-icon.svg');
    cursor: pointer;
  }

  .action-menu-month {
    background-color: white;
    display: flex;
    position: relative;
    flex-direction: column;

    .action-menu-title {
      font-size: 16px;
      font-weight: 600;
    }

    .action-btn {
      width: 200px;
      height: 44px;
      min-height: 44px;
      display: flex;
      justify-content: center;
      background-color: $appActionColor;
      color: $fontColorWhite;
      margin-top: 8px;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: $appActionColorHovered;
      }
    }

    .loading-indicator-container {
      cursor: wait;

      &:hover {
        background-color: $appActionColor;
      }

      .spinner {
        margin-top: 10px;
        border: 5px solid $whiteColor;
        border-radius: 50%;
        border-top: 5px solid $appActionColor;
        width: 15px;
        height: 15px;
        animation: spin 2s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .action-menu-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;

      .action-button {
        margin-top: 8px;
      }

      .month-wrapper {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        gap: 16px;
        align-items: center;
        margin-top: 8px;

        .loading-list-container {
          width: 250px;
          height: 44px;
          cursor: wait;
          .spinner {
            margin-top: 5px;
            border: 5px solid $whiteColor;
            border-radius: 50%;
            border-top: 5px solid $appActionColor;
            width: 25px;
            height: 25px;
            display: flex;
            margin-left: auto;
            margin-right: auto;
            animation: spin 2s linear infinite;
          }
        }

        .reports-month-field {
          background-color: white;
          width: 200px;
          height: 44px;
          display: flex;
          align-items: center;
          padding: 0 6px;
          position: relative;
          font-size: $fontSizeStandart;
          border: 1px solid $grayBorderColor;
          box-sizing: border-box;
          border-radius: 4px;
          cursor: pointer;

          .open-dropdown-btn {
            width: 20px;
            height: 20px;
            top: 12px;
            right: 19px;
            position: absolute;
            background-position: center;
            background-size: 20px;
            background-repeat: no-repeat;
            background-image: url('../../assets/icons/chevron-down-black-icon.svg');
          }

          .close-dropdown-btn {
            background-image: url('../../assets/icons/chevron-up-black-icon.svg');
          }
        }
      }
    }
  }
}
.modal-header-row {
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: calc(100% - 80px);
  color: $fontColorBlack;
  min-height: 46px;

  .goal-values {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  .title-container {
    display: flex;
    flex-direction: column;

    .title {
      font-size: $fontSizeTitle;
    }

    .current-date {
      font-size: $fontSizeMinified;
      color: $fontColorGray;
    }
  }

  .vertical-divider {
    width: 1px;
    height: 54px;
    margin-left: 20px;
    top: 35px;
    background: #c9cde8;
  }

  .closed-value-container {
    margin-left: 20px;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }
}
.modal-header-menu {
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: calc(100% - 80px);
  border-bottom: 1px solid var(--gray-200, #e2e8f0);
  color: $fontColorBlack;

  .tab-modal-element-wrapper {
    list-style-type: none;
    margin: 0;
    padding: 0px;
    overflow: hidden;
    background-color: white;
    cursor: pointer;
    align-items: flex-start;

    .tab-element-active-state {
      display: inline;
      float: left;
      padding: 14px 14px;
      color: #69c9a0;
      border-bottom: 4px solid #69c9a0;

      title {
        display: block;
        color: #69c9a0;
        text-decoration: none;
        text-align: center;
      }
    }

    .tab-element {
      display: inline;
      float: left;
      padding: 14px 14px;

      title {
        display: block;
        text-decoration: none;
        text-align: center;
        color: $fontColorBlack;
      }
    }
  }
}
.modal-table-body {
  width: calc(100% - 80px);

  .clients-list {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  .prospects-list {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
  .error-message {
    width: 100%;
    height: calc(100% - 204px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $redWarningColor;
  }

  .table-sort-options {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .sort-table-options {
      list-style-type: none;
      margin: 0;
      padding: 0px;
      color: $fontColorBlack;
      overflow: hidden;
      background-color: white;
      cursor: pointer;
      align-items: flex-start;

      .active-sort-state {
        display: inline;
        float: left;
        padding: 14px 14px;
        color: #69c9a0;
      }

      .sort-table-item {
        display: inline;
        float: left;
        padding: 14px 14px;
      }
    }
  }
  .modal-main-card {
    width: 100%;
    height: 100%;

    margin: 0;
  }
}

@media (min-height: 720px) {
  .modal-table-body {
    width: calc(100% - 80px);
    height: 45vh;
  }
}

@media (min-height: 1080px) {
  .modal-table-body {
    width: calc(100% - 80px);
    height: 60vh;
  }
}

@media (min-height: 1440px) {
  .modal-table-body {
    width: calc(100% - 80px);
    height: 70vh;
  }
}

@media (min-height: 2160px) {
  .modal-table-body {
    width: calc(100% - 80px);
    height: 80vh;
  }
}
</style>
